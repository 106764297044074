@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
 @font-face {
    font-family: 'Druk Web';
    font-display: swap;
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
    src: url('/fonts/Druk-Medium-Web.eot');
    src: url('/fonts/Druk-Medium-Web.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Druk-Medium-Web.woff2') format('woff2'),
         url('/fonts/Druk-Medium-Web.woff') format('woff');
    }

  @font-face {
    font-family: 'Druk Text Wide Web';
    font-display: swap;
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
    src: url('/fonts/DrukTextWide-Medium-Web.woff2') format('woff2'),
         url('/fonts/DrukTextWide-Medium-Web.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
        url('/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* Default Type styles */

  html {
    @apply text-gray-dark antialiased;
  }

  ul, ol {
    @apply ml-32 list-outside;

    li {
      /* @apply mb-8; turned off until navigation is updated to remove mb*/ 
    }
  }

  ol li {
    @apply list-decimal;
  }

  ul:not([class*="list-none"]) li { 
    @apply list-none relative;

    &:before {
      content: url('data:image/gif;base64,R0lGODlhEAAKAIABAMzMzP///yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOCAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0QkExQ0E4MTRCQzQxMUU4OTRDQUFDMDhCRjRBRUI5QyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0QkExQ0E4MjRCQzQxMUU4OTRDQUFDMDhCRjRBRUI5QyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkJDN0Y4QzJBNEJCRTExRTg5NENBQUMwOEJGNEFFQjlDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjRCQTFDQTgwNEJDNDExRTg5NENBQUMwOEJGNEFFQjlDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAQAAAQAsAAAAABAACgAAAg6Mj6nL7Q2inBS8i7PWBQA7');
      position: absolute;
      @apply -ml-32;
    }
  }

  ul.list-slash li:not(:last-child)::after {
    content: '/';
    padding: 0 0 0 4px;
  }
  
  p > a,
  li > a,
  .text-anchor {
    @apply text-blue-dark hover:underline cursor-pointer;
  }

 
  .prose {
    @apply space-y-16;

    figure {
      @apply mx-0;
    }

    table {
      @apply w-full border-collapse border-1 border-gray;
  
      th {
        @apply p-8;
      }
      
      tr:nth-of-type(odd) {
        @apply bg-gray-lighter;  
      }
      
      td {
        @apply py-8 px-16 border-1 border-gray;
      }
    }
  }

  /* forms */
  .form-stepper::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: block;
    @apply w-16;
    @apply h-32;
    background-image: url('https://gentux.imgix.net/1614858535_iconTriangleUpDown.png');
    background-size: 7px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    transform: translate(4px, 0);
  }
  
  input[type='file']::-webkit-file-upload-button {
    width: 0;
    padding: 0;
    border: none;
    overflow: hidden;
  }

  .container .container {
    padding-left: 0;
    padding-right: 0;
  }

  .row {
    @apply grid grid-cols-12 gap-x-16 sm:gap-x-32;
  }

  .m-section {
    @apply my-64 md:my-128;
  }
  .p-section {
    @apply py-64 md:py-128;
  }
  .m-section-hero {
    @apply mt-32 mb-64 xs:my-64 md:my-128;
  }
  .p-section-hero {
    @apply pt-32 pb-64 xs:py-64 md:py-128;
  }
}

@layer components {
  .polaroid {
    @apply border-white border-8 xs:border-16 shadow-3;
  }

  .btn,
  .blog-post [class*="button-"] {
    @apply inline-flex items-center justify-center gap-x-8 cursor-pointer;
    @apply py-16 px-24;
    @apply border-2 border-transparent;
    @apply text-black;
    @apply whitespace-nowrap text-center no-underline;
    @apply transition-all duration-150;
    @apply hover:bg-white hover:text-black hover:shadow-3 hover:no-underline;
    @apply disabled:cursor-not-allowed disabled:opacity-20 disabled:shadow-none;
  }

  .btn-sm {
    @apply py-8 px-16;
  }

  .btn-default {
    @apply border-b-gray bg-gray text-black;
    @apply disabled:bg-gray disabled:text-black;
  }
  .btn-info,
  .blog-post .button-info {
    @apply border-b-blue bg-blue text-black;
    @apply disabled:bg-blue disabled:text-black;
  }
  .btn-primary {
    @apply border-b-black bg-black text-white;
    @apply disabled:bg-black disabled:text-white;
  }
  .btn-danger {
    @apply border-b-red bg-red text-white;
    @apply disabled:bg-red disabled:text-white;
  }
  .btn-success {
    @apply border-b-green bg-green text-white;
    @apply disabled:bg-green disabled:text-white;
  }
  
  .btn-default-outline {
    @apply border-2 border-gray text-black;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-gray disabled:text-black;
  }
  .btn-info-outline {
    @apply border-2 border-blue text-blue-dark;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-blue disabled:text-blue;
  }
  .btn-primary-outline {
    @apply border-2 border-black text-black;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-black disabled:text-black;
  }
  .btn-danger-outline {
    @apply border-2 border-red text-red;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-red disabled:text-red;
  }
  .btn-success-outline {
    @apply border-2 border-green text-green;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-green disabled:text-green;
  }

  .full-width-buttons {
    .btn {
      @apply w-full;
    }
  }
}

@layer utilities {
  .hyphens {
    hyphens: auto;
  }

  .translate-z-0 {
    transform: translateZ(0);
  }

  @responsive {
    .scrolling-touch {
      -webkit-overflow-scrolling: touch;
    }
    .scrolling-auto {
      -webkit-overflow-scrolling: auto;
    }
    .scrollbar-none::-webkit-scrollbar {
      display: none;
    }

    .col-count-1 {
      column-count: 1;
    }
    .col-count-2 {
      column-count: 2;
    }
    .col-count-3 {
      column-count: 3;
    }
    .col-count-5 {
      column-count: 5;
    }
    .col-count-7 {
      column-count: 7;
    }
  }
}
